<template>
  <v-dialog v-model="dialogHistory" persistent max-width="1000px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-container fluid>

        <v-row>
          <v-col cols="12" lg="6" md="6" sm="6" class="pa-0 pb-5">
            <span class="display-2 font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.history") }}
            </span>
          </v-col>
          
          <v-col cols="12" lg="6" md="6" sm="6" class="pa-0 pb-5 text-end">
            <span class="display-2 font-weight-bold">
              {{ this.obj.po }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-list v-if="datas.length > 0" style="width: 100%">
            <v-list-group
              v-for="item in datas"
              :key="item.id"
              no-action
              style="border-bottom: 1px solid #ccc"
            >
              <v-list-item slot="activator">
                <v-list-item-content>
                  <v-list-item-title>{{ item.user_info.name }} {{ item.gen_time }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <!-- Expense Log History Info -->
                  <v-card elevation="0" v-if="dataType == 'fee_log'">
                    <v-card-text>
                      <p>Date: {{ item.data.pay_time }}</p>
                      <p>
                        Type:
                        <span v-if="item.data.in_out == 2" style="color: red">
                          Expense
                        </span>
                        <span dark small class="my-0" v-else style="color: blue">
                          Income
                        </span>
                      </p>
                      <p>Category: {{ item.category }}</p>
                      <p>Amount: {{ item.data.money }}</p>
                      <p>Currency: {{ item.data.currency }}</p>
                      <p>Company: {{ item.data.company }}</p>
                      <p>Address: {{ item.data.address }}</p>
                      <p>Bank Name: {{ item.data.bank_name }}</p>
                      <p>Bank Account: {{ item.data.account_no }}</p>
                      <p>Contact: {{ item.data.contact }}</p>
                      <p>Phone Number: {{ item.data.phone }}</p>
                      <p>Contact Email: {{ item.data.email }}</p>
                      <p>Other Contact: {{ item.data.other_contact }}</p>
                      <p>Remark: {{ item.data.remark }}</p>
                    </v-card-text>
                  </v-card>
                  
                  <!-- Financial Log History Info -->
                  <v-card elevation="0" v-if="dataType == 'finance_log'">
                    <v-card-text>
                      <p>Date: {{ item.data.date }}</p>
                      <p>
                        Category: 
                        <span v-if="item.data.type == 1">Bank Statements</span>
                        <span v-if="item.data.type == 2">Cash In/ Cash Out</span>
                        <span v-if="item.data.type == 3">Deposit/Withdraw</span>
                      </p>
                      <p>USD: {{ item.data.usd }}</p>
                      <p>KH: {{ item.data.kh }}</p>
                      <p>Bank Name: {{ item.data.bank_name }}</p>
                      <p>Bank Account: {{ item.data.bank_account }}</p>
                      <p>Remark: {{ item.data.note }}</p>
                      <p>
                        Attachment: 
                        <span v-if="item.data.attach.length > 0">
                          <span v-for="a in item.data.attach" :key="a">
                            {{ a }}
                            <span v-if="item.data.attach.length > 1">, </span>
                          </span>
                        </span>
                      </p>
                      <p>
                        Detail:
                        <span v-if="item.data.extend == '{}'">1</span>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-row>
        </v-container>
      </v-card-text>
        
      <v-card-actions class="py-6">
        <v-spacer />
        <v-btn @click="closeForm" color="blue">
          {{ this.$vuetify.lang.t("$vuetify.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { modifyLogList } from '@/api/financial'

export default {
  props: {
    dialogHistory: Boolean,
    obj: Object,
    dataType: String,
    category: Array,
  },
  data() {
    return {
      datas: [],
    }
  },
  methods: {
    getModifyLogList() {
      modifyLogList({ type: this.dataType, data_id: this.obj.id, skip: 0, limit: -1 })
        .then((res) => {
          this.datas = [...res.items];

          if (this.dataType == "fee_log") {
            this.datas.forEach(obj => {
              this.category.forEach(c => {
                
                if (c.id == obj.data.type) {
                  obj.category = c.name;
                }
              })
            })
          }
        })
    },
    closeForm() {
      this.$emit("update:dialogHistory", false);
    }
  },
  watch: {
    dialogHistory() {
      this.getModifyLogList();
    }
  }
}
</script>